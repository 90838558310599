<template>
  <div id="main_content">
    <section class="about" style="background-color: azure">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div
              class="section-title text-left"
              v-for="d in advertising"
              :key="d.id"
              style="background-color: white; border;"
            >
              <div class="row">
                <div class="col-lg-3">
                  <img :src="d.image_url" alt />
                </div>
                <div class="col-lg-9">
                  <h3 class="sub-title wow pixFadeUp">{{d.company}}</h3>
                  <h2 class="title wow pixFadeUp" data-wow-delay="0.3s">{{d.title}}</h2>
                  <p
                    class="description wow pixFadeUp text-justify"
                    data-wow-delay="0.4s"
                  >{{d.description}}</p>
                  <p
                    class="description wow pixFadeUp text-justify"
                    data-wow-delay="0.4s"
                    v-if="d.phone"
                  >{{$t('field_phone')}}: {{d.phone}}</p>
                  <p
                    class="description wow pixFadeUp text-justify"
                    data-wow-delay="0.4s"
                  >{{$t('field_email')}}: {{d.email}}</p>
                  <p
                    class="description wow pixFadeUp text-justify"
                    data-wow-delay="0.4s"
                  >{{$t('field_address')}}: {{d.address}}</p>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "home",
  data() {
    return {
      advertising: []
    };
  },
  async mounted() {
    await this.getAdvertising();
  },
  methods: {
    async getAdvertising() {
      const host = window.location.host;
      let url = "https://api.mikmic.com/api/";
      await this.axios.post(url + "advertising/getAllData").then((response) => {
        this.advertising = response.data;
      });
    }
  }
};
</script>